@import '../../../scss/varables';
@import '../../../scss/mixins';

.page-loading {
	position: fixed;
	top: 0rem;
	left: 0rem;
	width: 100%;
	height: 100vh;
	z-index: 5000;

	background-color: rgba($color-black-l-2, $alpha: 1);

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 1rem;

	&__loader-outer {
		overflow: hidden;
		z-index: 1;

		position: relative;
		width: 20rem;
		height: 3rem;

		background: $color-black-l-3;
		box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.9);
	}

	&__loader-inner {
		position: absolute;
		margin: auto;
		top: 0;
		left: 0;

		transform: translateY(50%);

		width: auto;

		color: $color-green;
		text-align: center;

		font-size: 60px;
		z-index: 2;
		animation: load 1s infinite linear;

		display: flex;
		justify-content: center;
		align-items: center;
		column-gap: 1rem;

		svg {
			width: 1.5rem;
			height: 1.5rem;
			background-color: $color-green;
			box-shadow: 0 0 4px 2px $color-green-d-5;
			border-radius: 1rem;
		}
	}

	h1 {
		width: 85px;
		 
		text-transform: uppercase;
		text-align: left;
		text-shadow: 0 0 30px $color-green-l-5;
		letter-spacing: 0.1em;
		font-size: 1.6rem;
		font-weight: lighter;
		color: $color-green;
		span {
			display: none;
		}
		&::after {
			animation: txt 1.5s infinite;
			content: '';
		}
	}

	@keyframes load {
		0% {
			left: -100px;
		}
		100% {
			left: 200px;
		}
	}

	@keyframes txt {
		0% {
			content: 'LOADING';
		}
		35% {
			content: 'LOADING.';
		}
		65% {
			content: 'LOADING..';
		}
		100% {
			content: 'LOADING...';
		}
	}
}
