@import '../../scss/varables';
@import '../../scss/mixins';

.home {
	 

	&__about {
		padding: 0rem !important;

		&-blur {
			z-index: 1;
			position: absolute;
			top: 0;
			left: 0;

			width: 100%;
			height: 100%;

			background-color: rgba($color-black-l-1, 0.5);
			backdrop-filter: blur(4px);
		}

		&-content {
			z-index: 2;
			position: relative;
			top: 0;
			left: 0;

			width: 100%;
			height: 100%;

			display: flex;
			flex-direction: column;
			align-items: center;

			gap: 2rem;
			padding: 2rem;

			@include breakpoint(desktop-small) {
				gap: 3rem;
				padding: 3rem;
			}

			background-color: rgba($color-black-l-1, $alpha: 0.8);
		}

		&-proverb {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			&-item {
				background-color: rgba($color-black-l-2, 0.4) !important;

				font-style: italic;
				font-weight: 700;
				font-size: 1.6rem;

				@include breakpoint(desktop-small) {
					font-size: 1.8rem;
				}

				@include breakpoint(desktop-normal) {
					font-size: 2rem;
				}

				@include breakpoint(desktop-large) {
					font-size: 2.2rem;
				}
			}
		}

		&-text {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			gap: 2rem;

			@include breakpoint(desktop-small) {
				gap: 3rem;
			}

			&-item {
				background-color: rgba($color-black-l-2, 0.4) !important;

				width: 100%;
				max-width: 410px;

				@include breakpoint(desktop-normal) {
					max-width: 450px;
				}

				@include breakpoint(desktop-large) {
					max-width: 480px;
				}
			}
		}

		&-cta {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 1rem;

			width: 100%;
			max-width: 360px;

			@include breakpoint(desktop-normal) {
				max-width: 400px;
			}

			@include breakpoint(desktop-large) {
				max-width: 480px;
			}

			p {
				font-weight: 700;
			}

			&-button {
				padding: 1rem;
				margin-top: 1rem;
				position: relative;

				background-color: $color-green;

				font-weight: 700;
				text-decoration: none;
				color: $color-black-l-1;
				border-radius: 0.2rem;

				cursor: pointer;
				transition: all 0.5s ease;

				&:hover {
					background-color: $color-green-d-5;
				}
			}
		}

		video {
			width: 100%;
			height: 100%;
			position: absolute;
			object-fit: cover;
			z-index: 0;
			filter: hue-rotate(-70deg);
		}
	}

	&__services {
		&-wrapper {
			max-width: 1600px !important;
		}

		&-item {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;

			width: 100%;
			gap: 2rem;

			font-size: 1.4rem;

			@include breakpoint(mobile-large) {
				width: 45%;
				height: 240px;
			}

			@include breakpoint(desktop-small) {
				width: 100%;
				height: auto;
				gap: 2rem;
				font-size: 1.5rem;
			}

			@include breakpoint(desktop-small-1) {
				width: 45%;
				height: 260px;
			}

			@include breakpoint(desktop-normal) {
				width: 45%;
				height: 270px;
				font-size: 1.6rem;
			}

			@include breakpoint(desktop-normal-1) {
				width: 30%;
				height: 270px;
			}

			@include breakpoint(desktop-large) {
				font-size: 1.7rem;
				height: 290px;
			}

			&-header {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 1rem;

				width: 100%;

				h3 {
					display: flex;
					align-items: center;
					font-size: inherit;
				}
			}

			&-text {
				font-size: inherit;
				text-align: left;
			}

			&-link {
				font-size: inherit;

				margin-top: 0.4rem;
				padding: 0.2rem 0.8rem;

				color: $color-black-l-1;
				background-color: $color-green;
				border-radius: 0.2rem;
				font-weight: 700;

				text-decoration: none;
				transition: all 0.5s ease;

				&:hover {
					background-color: $color-green-d-5;
				}

				@include breakpoint(desktop-small) {
					padding: 0.4rem 1rem;
				}

				@include breakpoint(desktop-normal) {
					padding: 0.6rem 1.2rem;
				}
			}

			.icon {
				color: $color-black-l-5;
				height: 3.3rem;
				display: block;

				position: absolute;
				bottom: 2rem;
				right: 2rem;

				@include breakpoint(desktop-small) {
					bottom: 3rem;
					right: 3rem;
					height: 3.6rem;
				}
			}
		}
	}
}
