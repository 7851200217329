@import '../../../scss/varables';
@import '../../../scss/mixins';

.menu-border {
	position: fixed;
	top: 0%;

	width: 100%;
	min-height: 62px;

	z-index: 999;

	background-color: $color-black-l-1;
	border-bottom: 2px solid $color-black-l-2;

	@include breakpoint(desktop-small) {
		display: none;
	}
}
