@import '../../../scss/varables';
@import '../../../scss/mixins';

.card {
	padding: 2rem;
	width: 100%;
	background-color: $color-black-l-2;
	color: $color-white-d-5;

	font-family: 'Tomorrow', sans-serif;
	letter-spacing: 0.1rem;
	border-radius: 0.2rem;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	

	&__container {
		width: 100%;
		display: flex;
	}

	&__header {
		font-size: 1.3rem;
		text-align: left;
		font-weight: 700;
		width: 100%;

		@include breakpoint(desktop-normal) {
			font-size: 1.4rem;
		}

		@include breakpoint(desktop-large) {
			font-size: 1.5rem;
		}
	}
}
