@import '../../../scss/varables';
@import '../../../scss/mixins';

.section-loading {
	position: fixed;
	top: 0;
	width: 100%;
	height: 100vh;
	z-index: 5000;

	z-index: 997;

	background-color: rgba($color-black-l-1, $alpha: 1);

	display: flex;
	justify-content: center;
	align-items: center;

	.simple-spinner {
		width: 100px;
		height: 100px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.simple-spinner span {
		display: block;
		width: 100px;
		height: 100px;
		border: 10px solid transparent;
		border-radius: 50%;
		border-right-color: $color-green;
		border-bottom-color: $color-green-l-5;
		animation: spinner-anim 0.8s linear infinite;
	}

	@keyframes spinner-anim {
		from {
			transform: rotate(0);
		}
		to {
			transform: rotate(360deg);
		}
	}
}
