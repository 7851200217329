@import '../../../../scss/varables';
@import '../../../../scss/mixins';

.menu {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 1rem;

	position: absolute;
	top: 140px;

	width: 100%;
	padding-left: 60px;

	transform: translateY(-50%);

	@include breakpoint(desktop-normal) {
		top: 150px;
		padding-left: 70px;
	}

	@include breakpoint(desktop-large) {
		top: 160px;
		padding-left: 80px;
	}

	&__item {
		color: $color-white-d-5;

		letter-spacing: 0.3rem;
		text-decoration: none;

		@include breakpoint(desktop-large) {
			font-size: 1.8rem;
		}

		transform: translateX(100%);

		&:nth-child(1) {
			transition: 0.2s ease all;
		}

		&:nth-child(2) {
			transition: 0.3s ease all;
		}

		&:nth-child(3) {
			transition: 0.4s ease all;
		}

		&:nth-child(4) {
			transition: 0.5s ease all;
		}

		&:nth-child(5) {
			transition: 0.6s ease all;
		}

		&--active {
			font-weight: 700;
			color: $color-green;
		}

		&:hover {
			color: $color-green;
		}
	}

	&--active {
		.menu__item {
			transform: translateX(0);
		}
	}
}
