@import url(https://fonts.googleapis.com/css?family=Lato:700);

@import '../../../scss/varables';
@import '../../../scss/mixins';

// Source code:
// https://codepen.io/jo-asakura/pen/NWWrWj
// modified for current project

// -- vars
$default-size: 6rem;

// -- mixins
@mixin size($width, $height) {
	height: $height;
	width: $width;
}

@mixin draw-progress($progress) {
	.pie {
		.left-side {
			transform: rotate($progress * 3.6deg);
		}

		@if $progress <= 50 {
			.right-side {
				display: none;
			}
		} @else {
			clip: rect(auto, auto, auto, auto);

			.right-side {
				transform: rotate(180deg);
			}
		}
	}
}

.pie-wrapper {
	width: $default-size;
	height: $default-size;
	position: relative;

	.pie {
		width: 100%;
		height: 100%;

		clip: rect(0, $default-size, $default-size, $default-size / 2);
		left: 0;
		position: absolute;
		top: 0;

		.half-circle {
			@include size(100%, 100%);
			border: ($default-size / 10) solid $color-green;
			border-radius: 50%;
			clip: rect(0, $default-size / 2, $default-size, 0);
			left: 0;
			position: absolute;
			top: 0;
		}
	}

	.label {
		position: absolute;
		top: 100%;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

		font-size: 1.2rem;
		font-weight: 700;

		.smaller {
			font-size: 0.7rem;
			padding-bottom: 20px;
			vertical-align: super;
		}
	}

	.shadow {
		width: 100%;
		height: 100%;

		border: $default-size / 10 solid $color-black-l-3;
		border-radius: 50%;
	}

	&.progress-100 {
		@include draw-progress(100);
	}

	&.progress-65 {
		@include draw-progress(65);
	}

	&.progress-10 {
		@include draw-progress(10);
	}
}
