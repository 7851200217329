@import '../../../../scss/varables';
@import '../../../../scss/mixins';

.side {
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	width: 100%;
	height: 100vh;
	display: flex;

	color: $color-white-d-5;

	font-family: 'Tomorrow', sans-serif;

	&__shadow {
		background-color: $color-black-l-1;
		width: 100%;
		height: 0.8rem;
		z-index: 1;
		box-shadow: -3px 5px 4px 0px $color-black;

		&:last-child {
			box-shadow: -3px -5px 4px 0px $color-black;
		}

		@include breakpoint(desktop-normal) {
			height: 1.2rem;
		}

		@include breakpoint(desktop-large) {
			height: 1.6rem;
		}
	}

	&__self {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 1rem;

		padding: 2rem 0rem;

		background-color: $color-black-l-1;
		border-bottom: 2px solid $color-black-l-2;

		&-photo {
			width: 45%;
			border-radius: 50%;
			display: block;
			border: 5px solid $color-black-l-3;
		}

		&-info {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			letter-spacing: 0.1rem;

			&--main {
				font-weight: 700;
				font-size: 1.8rem;

				@include breakpoint(desktop-normal) {
					font-size: 2rem;
				}

				@include breakpoint(desktop-large) {
					font-size: 2.2rem;
				}
			}

			&--sub {
				font-size: 1.4rem;

				@include breakpoint(desktop-normal) {
					font-size: 1.6rem;
				}

				@include breakpoint(desktop-large) {
					font-size: 2rem;
				}
			}
		}
	}

	&__content {
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: flex-start;
		gap: 2rem;

		width: 100%;
		height: 100%;
		padding: 1.2rem 2rem;

		position: relative;

		overflow-y: auto;

		font-size: 1.3rem;

		&::-webkit-scrollbar {
			width: 0;
			background-color: transparent;
		}

		@include breakpoint(desktop-normal) {
			font-size: 1.4rem;
			padding: 1.6rem 2rem;
		}

		@include breakpoint(desktop-large) {
			font-size: 1.5rem;
			padding: 2rem 2rem;
		}
	}

	&__info {
		&-container {
			flex-direction: column;
			gap: 0.4rem;

			@include breakpoint(desktop-normal) {
				gap: 0.6rem;
			}

			@include breakpoint(desktop-large) {
				gap: 0.8rem;
			}
		}

		&-item {
			display: flex;
			justify-content: space-between;

			&--main {
				font-weight: 700;
			}
		}
	}

	&__languages {
		gap: 1.5rem;

		@include breakpoint(desktop-normal) {
			gap: 1.75rem;
		}

		@include breakpoint(desktop-large) {
			gap: 2rem;
		}

		&-container {
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}

		&-progress {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 0.5rem;
		}
	}

	&__coding {
		gap: 1.2rem;

		@include breakpoint(desktop-normal) {
			gap: 1.4rem;
		}

		@include breakpoint(desktop-large) {
			gap: 1.6rem;
		}

		&-container {
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			gap: 1rem;

			padding-bottom: 0.2rem;

			@include breakpoint(desktop-normal) {
				gap: 1.2rem;
			}

			@include breakpoint(desktop-large) {
				gap: 1.4rem;
			}
		}
	}

	&__knowledge {
		gap: 1rem;

		@include breakpoint(desktop-normal) {
			gap: 1.2rem;
		}

		@include breakpoint(desktop-large) {
			gap: 1.4rem;
		}

		&-container {
			flex-direction: column;
			align-items: flex-start;
			padding-left: 1rem;
			padding-bottom: 0.2rem;

			gap: 0.4rem;

			@include breakpoint(desktop-normal) {
				padding-left: 1.2rem;
				gap: 0.6rem;
			}

			@include breakpoint(desktop-large) {
				padding-left: 1.4rem;
				gap: 0.8rem;
			}

			div {
				&::before {
					content: '\2713';
					color: $color-green;
					font-weight: bold;
					margin-right: 0.8rem;
				}
			}
		}
	}
}
