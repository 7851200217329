@import '../../../scss/varables';
@import '../../../scss/mixins';

.footer {
	position: relative;
	width: 100%;
	padding: 2rem 2.6rem;

	background-color: $color-black-l-1;
	border-top: 2px solid $color-black-l-2;
	color: $color-white-d-5;

	&__wrapper {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		padding: 2rem;
		gap: 0.5rem;
		background-color: $color-black-l-2;

		font-size: 1.4rem;
		font-weight: 700;

		@include breakpoint(ex-small) {
			flex-direction: row;
			justify-content: space-between;
		}

		@include breakpoint(desktop-normal) {
			font-size: 1.5rem;
		}

 
	}

	&__about {
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 700;

		span {
			color: $color-green;
		}
	}

	@include breakpoint(desktop-small) {
		padding-top: 3rem;
		padding-bottom: 3rem;
		padding-left: 31.4rem;
		padding-right: 9.4rem;
	}

	@include breakpoint(desktop-normal) {
		padding-right: 11.4rem;
		padding-left: 33.4rem;
	}

	@include breakpoint(desktop-large) {
		padding-right: 13.4rem;
		padding-left: 35.4rem;
	}
}
