@import '../../scss/varables';
@import '../../scss/mixins';

.contact {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;

	&__box {
		width: 100%;
		justify-content: flex-start;

		@include breakpoint(desktop-small-1) {
			width: auto;
			flex: 1;

			&:last-child {
				width: 100%;
			}
		}
	}

	&__info {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		gap: 2rem;

		&-box {
			background-color: $color-black-l-2;
			flex: 1 1 280px;
			padding: 2rem;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 1rem;
		}

		&-text {
			width: 100%;
			background-color: $color-black-l-2;
			padding: 2rem;
			font-size: 1.6rem;

			@include breakpoint(desktop-small-1) {
				font-size: 1.7rem;
			}
		}

		&-item {
			width: 100%;

			display: flex;
			justify-content: space-between;
			align-items: center;

			font-size: 1.6rem;

			p {
				&:first-child {
					font-weight: 700;
				}
			}

			a {
				img {
					width: 100%;
				}
			}
		}
	}

	&__api {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		gap: 2rem;

		&-wrapper {
			width: 100%;
		}

		&-form {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			gap: 2rem;
			width: 100%;
			position: relative;

			&-field {
				width: 100%;
				max-width: 800px;

				display: flex;
				justify-content: center;
				align-items: flex-start;

				background-color: $color-black-l-3;

				&-icon {
					background-color: $color-black-l-3;

					display: flex;
					justify-content: center;
					align-items: center;
				}

				svg {
					width: 2rem;
					height: 2rem;
					color: $color-green;
					padding: 1rem;

					@include breakpoint(desktop-small) {
						width: 2.5rem;
						height: 2.5rem;
						padding: 1.25rem;
					}

					@include breakpoint(desktop-normal) {
						width: 3rem;
						height: 3rem;
						padding: 1.5rem;
					}
				}
			}

			&-input {
				border-radius: none;
				border: none;
				display: block;

				width: 100%;
				padding: 1rem;

				border: 2px solid $color-black-l-3;
				border-left: none;

				background-color: $color-black-l-1;
				color: $color-white-d-5;

				font-family: inherit;
				font-size: 1.6rem;

				transition: all ease 0.5s;

				&::placeholder {
					opacity: 0.5;
					color: $color-black-l-5;
				}

				&:hover,
				&:focus {
					background-color: $color-black-l-2;
					outline: none;
				}

				@include breakpoint(desktop-small) {
					height: 5rem;
				}

				@include breakpoint(desktop-normal) {
					height: 6rem;
				}
			}

			&-buttons {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 1rem;
			}

			&-text {
				margin-top: -1rem;
				font-size: 1.2rem;

				display: flex;
				gap: 1rem;

				@include breakpoint(desktop-small) {
					font-size: 1.3rem;
				}

				@include breakpoint(desktop-normal) {
					font-size: 1.4rem;
				}

				span {
					cursor: pointer;
				}
			}

			&-submit {
				font-family: inherit;
				font-size: 1.4rem;
				cursor: pointer;

				padding: 1rem 2rem;

				color: $color-black-l-1;
				background-color: $color-green;
				border: none;
				border-radius: 0.2rem;
				font-weight: 700;

				text-decoration: none;
				transition: all 0.5s ease;

				&:hover,
				&:focus {
					outline: none;
					background-color: $color-green-d-5;
				}

				@include breakpoint(desktop-small) {
					font-size: 1.5rem;
				}

				@include breakpoint(desktop-normal) {
					font-size: 1.6rem;
				}
			}
		}

		&-box {
			background-color: $color-black-l-2;
			width: 100%;
			padding: 2rem;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 1rem;

			font-size: 1.6rem;

			@include breakpoint(desktop-small-1) {
				font-size: 1.7rem;
			}
		}

		&-submit {
			font-family: inherit;
			font-size: inherit;

			padding: 1rem 2rem;

			color: $color-black-l-1;
			background-color: $color-green;
			border: none;
			border-radius: 0.2rem;
			font-weight: 700;

			text-decoration: none;
			transition: all 0.5s ease;

			&:hover,
			&:focus {
				outline: none;
				background-color: $color-green-d-5;
			}

			@include breakpoint(desktop-small) {
				padding: 1.3rem 2.4rem;
			}

			@include breakpoint(desktop-normal) {
				padding: 1.6rem 2.8rem;
			}
		}

		&-recovery {
			z-index: 1;
			position: absolute;
			background-color: rgba($color: #000000, $alpha: 0.95);
			inset: 1rem 1rem 1rem 1rem;

			display: flex;
			justify-content: center;
			align-items: center;

			padding: 0rem 3rem;

			&-buttons {
				display: flex;
				flex-wrap: wrap;
				gap: 1rem;

				&-close {
					font-family: inherit;
					font-size: 1.4rem;

					cursor: pointer;
					padding: 1rem 2rem;

					color: $color-white-d-5;
					background-color: $color-black-l-3;
					border: none;
					border-radius: 0.2rem;
					font-weight: 700;

					text-decoration: none;
					transition: all 0.5s ease;

					&:hover,
					&:focus {
						outline: none;
						background-color: $color-black-l-2;
					}

					@include breakpoint(desktop-small) {
						padding: 1.3rem 2.4rem;
					}

					@include breakpoint(desktop-normal) {
						padding: 1.6rem 2.8rem;
					}
				}

				&-delete {
					font-family: inherit;
					font-size: 1.4rem;

					cursor: pointer;
					padding: 1rem 2rem;
					color: $color-white-d-5;
					background-color: rgb(102, 8, 8);
					border: none;
					border-radius: 0.2rem;
					font-weight: 700;

					text-decoration: none;
					transition: all 0.5s ease;

					&:hover,
					&:focus {
						outline: none;
						background-color: rgb(68, 5, 5);
					}
				}
			}

			&-off {
				display: none;
			}
		}

		&-information {
			
			display: flex;
			flex-direction: column;
			gap: 2rem;

			p {
				span {
					color: $color-white-d-5;
				}
			}

			&-buttons {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 1rem;
			}

			&-log {
				font-family: inherit;
				font-size: 1.4rem;

				cursor: pointer;
				padding: 1rem 2rem;

				color: $color-white-d-5;
				background-color: $color-black-l-3;
				border: none;
				border-radius: 0.2rem;
				font-weight: 700;

				text-decoration: none;
				transition: all 0.5s ease;

				&:hover,
				&:focus {
					outline: none;
					background-color: $color-black-l-2;
				}

				@include breakpoint(desktop-small) {
					padding: 1.3rem 2.4rem;
				}

				@include breakpoint(desktop-normal) {
					padding: 1.6rem 2.8rem;
				}
			}

			&-delete {
				font-family: inherit;
				font-size: 1.4rem;

				cursor: pointer;
				padding: 1rem 2rem;

				color: $color-white-d-5;
				background-color: rgb(102, 8, 8);
				border: none;
				border-radius: 0.2rem;
				font-weight: 700;

				text-decoration: none;
				transition: all 0.5s ease;

				&:hover,
				&:focus {
					outline: none;
					background-color: rgb(68, 5, 5);
				}

				@include breakpoint(desktop-small) {
					padding: 1.3rem 2.4rem;
				}

				@include breakpoint(desktop-normal) {
					padding: 1.6rem 2.8rem;
				}
			}
		}
	}

	&__form {
		width: 100%;

		&-message-off {
			display: none;
		}

		&-message {
			z-index: 2;
			position: absolute;
			background-color: rgba($color-black-l-1, 0.8);
			border: 2px solid $color-black-l-1;

			top: 50%;

			left: 1rem;
			right: 1rem;
			top: 1rem;
			bottom: 1rem;

			display: flex;
			align-items: center;
			justify-content: center;

			font-size: 1.8rem;

			p {
				width: 100%;
				max-width: 400px;
				padding: 2rem 4rem;
				background-color: $color-black-l-2;
			}

			@include breakpoint(mobile-large) {
				font-size: 1.9rem;
			}

			@include breakpoint(desktop-small) {
				font-size: 2rem;
			}

			@include breakpoint(desktop-normal) {
				font-size: 2.1rem;
			}

			&--good {
				p {
					color: green;
				}
			}

			&--bad {
				p {
					color: red;
				}
			}
		}

		&-loading-off {
			display: none;
		}

		&-loading {
			position: absolute;
			top: -2rem;
			left: -2rem;
			right: -2rem;
			bottom: -2rem;
			z-index: 1;

			background-color: rgba($color-black-l-1, $alpha: 0.9);

			display: flex;
			justify-content: center;
			align-items: center;

			.simple-spinner {
				width: 100px;
				height: 100px;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			}

			.simple-spinner span {
				display: block;
				width: 100px;
				height: 100px;
				border: 10px solid transparent;
				border-radius: 50%;
				border-right-color: $color-green;
				border-bottom-color: $color-green-d-5;
				animation: spinner-anim 0.8s linear infinite;
			}

			@keyframes spinner-anim {
				from {
					transform: rotate(0);
				}
				to {
					transform: rotate(360deg);
				}
			}
		}

		&-wrapper {
			width: 100%;
		}

		.form {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 2rem;
			width: 100%;
			position: relative;

			&__field {
				width: 100%;

				display: flex;
				justify-content: center;
				align-items: flex-start;

				max-width: 800px;

				background-color: $color-black-l-3;

				@include breakpoint(desktop-normal) {
					max-width: 1000px;
				}

				&-icon {
					background-color: $color-black-l-3;

					display: flex;
					justify-content: center;
					align-items: center;
				}

				svg {
					width: 2rem;
					height: 2rem;
					color: $color-green;
					padding: 1rem;

					@include breakpoint(desktop-small) {
						width: 2.5rem;
						height: 2.5rem;
						padding: 1.25rem;
					}

					@include breakpoint(desktop-normal) {
						width: 3rem;
						height: 3rem;
						padding: 1.5rem;
					}
				}
			}

			&__success {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				gap: 2rem;
			}

			&__input {
				border-radius: none;
				border: none;
				display: block;

				width: 100%;
				padding: 1rem;
				height: 4rem;

				border: 2px solid $color-black-l-3;
				border-left: none;

				background-color: $color-black-l-1;
				color: $color-white-d-5;

				font-family: inherit;
				font-size: 1.6rem;

				transition: all ease 0.5s;

				&::placeholder {
					opacity: 0.5;
					color: $color-black-l-5;
				}

				&:hover,
				&:focus {
					background-color: $color-black-l-2;
					outline: none;
				}

				@include breakpoint(desktop-small) {
					height: 5rem;
				}

				@include breakpoint(desktop-normal) {
					height: 6rem;
				}
			}

			&__textarea {
				resize: none;
				height: 8rem;

				@include breakpoint(desktop-small) {
					height: 10rem;
				}

				@include breakpoint(desktop-normal) {
					height: 12rem;
				}
			}

			&__submit {
				font-family: inherit;
				font-size: inherit;

				padding: 1rem 2rem;

				color: $color-black-l-1;
				background-color: $color-green;
				border: none;
				border-radius: 0.2rem;
				font-weight: 700;

				text-decoration: none;
				transition: all 0.5s ease;

				&:hover,
				&:focus {
					outline: none;
					background-color: $color-green-d-5;
				}

				@include breakpoint(desktop-small) {
					padding: 1.3rem 2.4rem;
				}

				@include breakpoint(desktop-normal) {
					padding: 1.6rem 2.8rem;
				}
			}

			iframe {
				border: 2px solid black;
			}
		}
	}
}
