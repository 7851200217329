@import '../../../scss/varables';
@import '../../../scss/mixins';

.sidebar {
	position: fixed;

	max-width: 28rem;
	height: 100vh;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	z-index: 1000;

	background-color: $color-black-l-1;
	border-right: 2px solid $color-black-l-2;

	transform: translateX(-100%);
	transition: 0.5s ease all;

	@include breakpoint(desktop-small) {
		transform: translateX(0px);
	}

	@include breakpoint(desktop-normal) {
		max-width: 30rem;
	}

	@include breakpoint(desktop-large) {
		max-width: 32rem;
	}

	&__button {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		width: 100%;
		padding: 10px;

		transform: translateX(60px);
		transition: 0.5s ease all;
		z-index: 101;

		position: absolute;

		color: $color-green;

		@include breakpoint(desktop-small) {
			display: none;
		}

		span {
			width: 40px;
			height: 40px;

			display: flex;
			justify-content: center;
			align-items: center;

			cursor: pointer;

			svg {
				height: 60%;
			}
		}

		&--hide {
			opacity: 0;
			transform: translateX(0px);
		}
	}

	&__box {
		overflow-y: scroll;
	}

	&--active {
		transform: translateX(0%);

		.sidebar__button {
			transform: translateX(0%);
		}
	}

	
}
