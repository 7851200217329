@import '../../../scss/varables';
@import '../../../scss/mixins';

.skillbox {
	box-sizing: border-box;
	width: 100%;

	display: flex;
	flex-direction: column;

	&__text {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		p {
			text-transform: uppercase;
			padding: 0;
			letter-spacing: 0.1rem;

			font-size: 1.1rem;

			@include breakpoint(desktop-normal) {
				font-size: 1.2rem;
			}

			@include breakpoint(desktop-large) {
				font-size: 1.3rem;
			}

			&:first-child {
				font-weight: 700;
			}
		}
	}
}

.skill {
	background: $color-black-l-3;
	border-radius: 1rem;
}

.skill_level {
	background: $color-green;
	width: 90%;
	height: 0.6rem;
	border-radius: 1rem;
}

.progress-80 {
	.skill_level {
		width: 80%;
	}
}

.progress-70 {
	.skill_level {
		width: 70%;
	}
}

.progress-65 {
	.skill_level {
		width: 65%;
	}
}

.progress-60 {
	.skill_level {
		width: 60%;
	}
}

.progress-50 {
	.skill_level {
		width: 50%;
	}
}

.progress-45 {
	.skill_level {
		width: 45%;
	}
}

.progress-40 {
	.skill_level {
		width: 40%;
	}
}
