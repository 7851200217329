@import '../../../scss/varables';
@import '../../../scss/mixins';

.main {
	padding-top: 60px;
	position: relative;
	transition: 0.5s ease all;
	padding: 6.6rem 0.4rem 0.4rem;
	color: $color-mono-d-5;

	&__swap {
		width: 100%;

		display: flex;
		flex-direction: column;
		align-items: center;

		transition: 0s ease all;

		padding: 2rem;

		&--active {
			opacity: 0;
			transform: scale(0.4);
			transition: 0.25s;
		}
	}

	@include breakpoint(desktop-small) {
		padding-top: 1.4rem;
		padding-bottom: 1.4rem;
		padding-left: 29.4rem;
		padding-right: 7.4rem;
	}

	@include breakpoint(desktop-normal) {
		padding-right: 9.4rem;
		padding-left: 31.4rem;
	}

	@include breakpoint(desktop-large) {
		padding-right: 11.4rem;
		padding-left: 33.4rem;
	}

	&--side {
		transform: translateX(150px) rotate(5deg);
	}

	&--menu {
		transform: translateX(-150px) rotate(-5deg);

		@include breakpoint(desktop-small) {
			transform: translateX(-200px) rotate(-5deg);
		}
	}
}
