@import '../../../scss/varables';
@import '../../../scss/mixins';

.page-section {
	z-index: 0;
	position: relative;
	overflow: hidden;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2rem;

	width: 100%;
	padding: 2rem;

	background-color: rgba($color-black-l-1, 1);
	border: 2px solid $color-black-l-2;

	color: $color-white-d-5;
	text-align: center;
	font-size: 1.5rem;

	@include breakpoint(desktop-small) {
		gap: 3rem;
		padding: 3rem;
		font-size: 1.6rem;
	}

	@include breakpoint(desktop-normal) {
		font-size: 1.7rem;
	}

	@include breakpoint(desktop-large) {
		font-size: 1.8rem;
	}

	&__wrapper {
		width: 100%;
		max-width: 1600px;

		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		gap: 2rem;

		@include breakpoint(desktop-small) {
			gap: 3rem;
		}
	}

	&__header {
		padding: 0 0.6rem 0.2rem;
		margin-bottom: 0.8rem;
		border-bottom: 3px solid $color-green;

		font-style: italic;
		font-size: 1.6rem;

		@include breakpoint(desktop-small) {
			font-size: 1.8rem;
		}

		@include breakpoint(desktop-normal) {
			font-size: 2rem;
		}

		@include breakpoint(desktop-large) {
			font-size: 2.2rem;
		}
	}

	&__item {
		padding: 2rem;
		border-radius: 0.2rem;
		background-color: $color-black-l-2;
		box-shadow: 0px 0px 3px 0px $color-black-l-2;

		@include breakpoint(desktop-small) {
			padding: 3rem;
		}
	}

	span {
		color: $color-green;
		font-weight: 700;
	}
}
