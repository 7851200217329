@import '../../../scss/varables';
@import '../../../scss/mixins';

.backdrop {
	position: fixed;

	height: 100%;
	width: 100vw;

	background-color: rgba($color: $color-black, $alpha: 0.9);

	opacity: 0;
	transition: 0.3s ease all;

	z-index: 998;

	&--active {
		opacity: 1;
		cursor: pointer;
	}

	@include breakpoint(desktop-small) {
		&--active {
			z-index: 1100;
		}
	}
}
