$breakpoints-up: (
	'ex-small': 42em,
	//576
	'mobile-large': 48em,
	//768
	'desktop-small': 64em,
	//1024
	'desktop-small-1': 80em,
	//1280
	'desktop-normal': 90em,
	//1440
	'desktop-normal-1': 110em,
	//1760
	'desktop-large': 116em,
	//1920
);

@mixin breakpoint($size) {
	@media (min-width: map-get($breakpoints-up, $size)) {
		@content;
	}
}

$sudoku-cell-breakpoints: (
	'500': 31.25em,
	//500
	'600': 37.5em,
	//600
	'820': 51.25em,
	//820
	'1024': 64em,
	//1024
	'1200': 75em,
	//1200
	'1440': 90em,
	//1440
	'1920': 116em,
	//1920
);

@mixin sudoku-breakpoint($size) {
	@media (min-width: map-get($sudoku-cell-breakpoints, $size)) {
		@content;
	}
}
