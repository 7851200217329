@import '../../scss/varables';
@import '../../scss/mixins';

.portfolio {
	position: relative;

	&__wrapper {
		max-width: 1400px !important;
		min-height: 80vh;

		align-items: flex-start;
	}

	&__nav {
		display: flex;
		gap: 2rem;
		margin-bottom: 1rem;

		&-item {
			padding: 0.4rem 1rem;
			border-radius: 0.2rem;

			background-color: $color-green;
			color: $color-black-l-1;
			font-weight: 700;
			cursor: pointer;
			transition: 0.5s ease all;

			&--active {
				background-color: $color-green-l-5;
			}

			&:hover {
				background-color: $color-green-l-3;
			}
		}
	}

	&__item {
		padding: 0rem !important;

		position: relative;
		display: flex;
		flex-direction: column;

		width: 100%;
		max-width: 300px;

		border: $color-black-l-3 2px solid;

		@include breakpoint(desktop-small) {
			max-width: 320px;
		}

		@include breakpoint(desktop-normal) {
			max-width: 340px;
		}

		@include breakpoint(desktop-large) {
			max-width: 360px;
		}

		&--hide {
			display: none;
		}

		&-image {
			width: 100%;
			display: block;
			border-radius: 0.2rem 0.2rem 0rem 0rem;
		}

		&-box {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 1rem;

			padding: 2rem 1rem;
			height: 230px;

			@include breakpoint(desktop-small) {
				padding: 2rem;
			}

			@include breakpoint(desktop-normal) {
				height: 240px;
			}

			@include breakpoint(desktop-large) {
				height: 260px;
			}
		}

		&-header {
			font-weight: 700;
			font-size: 1.5rem;

			@include breakpoint(desktop-normal) {
				font-size: 1.6rem;
			}

			@include breakpoint(desktop-large) {
				font-size: 1.7rem;
			}
		}

		&-content {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 1rem;
		}

		&-text {
			font-size: 1.4rem;

			@include breakpoint(desktop-normal) {
				font-size: 1.5rem;
			}

			@include breakpoint(desktop-large) {
				font-size: 1.6rem;
			}
		}

		&-keywords {
			font-size: 1.1rem;
			font-style: italic;
			color: $color-white-d-6;
			color: $color-green;
			font-weight: 700;

			@include breakpoint(desktop-normal) {
				font-size: 1.2rem;
			}

			@include breakpoint(desktop-large) {
				font-size: 1.3rem;
			}
		}

		&-link {
			font-size: 1.5rem;
			text-decoration: none;
			color: $color-white-d-5;

			display: flex;
			justify-content: center;
			align-items: center;

			@include breakpoint(desktop-normal) {
				font-size: 1.6rem;
			}

			@include breakpoint(desktop-large) {
				font-size: 1.7rem;
			}

			svg {
				padding-top: 0.3rem;
				padding-left: 0.5rem;
				width: 1rem;
				height: 1rem;

				transition: 0.2s ease all;

				@include breakpoint(desktop-normal) {
					width: 1.5rem;
					height: 1.5rem;
					padding-top: 0.2rem;
				}
			}

			&:hover {
				svg {
					padding-left: 1rem;

					@include breakpoint(desktop-normal) {
						padding-left: 1.5rem;
					}
				}
			}
		}
	}
}
