@import '../../../scss/varables';
@import '../../../scss/mixins';

.menubar {
	z-index: 1000;

	position: fixed;
	right: 0px;

	width: 300px;
	height: 100vh;

	display: flex;
	flex-direction: column;

	background-color: $color-black-l-1;
	border-left: 2px solid $color-black-l-2;

	font-family: 'Tomorrow', sans-serif;

	transform: translateX(100%);
	transition: 0.5s ease all;

	@include breakpoint(desktop-small) {
		z-index: 1111;
		transform: translateX(240px);
	}

	@include breakpoint(desktop-normal) {
		width: 320px;
	}

	@include breakpoint(desktop-large) {
		width: 340px;
	}

	&__container {
		position: relative;

		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;

		width: 100%;
		height: 100%;
	}

	&--active {
		transform: translateX(0%);
	}
}

.menubar__button {
	z-index: 101;

	display: flex;
	justify-content: flex-start;
	align-items: center;

	width: 100%;
	padding: 10px;

	background-color: $color-black-l-1;
	border-bottom: 2px solid $color-black-l-2;

	@include breakpoint(desktop-normal) {
		padding: 20px;
	}

	@include breakpoint(desktop-large) {
		padding: 30px;
	}

	span {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 0.3rem;

		width: 40px;
		height: 40px;

		cursor: pointer;

		transform: translateX(-60px);
		transition: 0.5s ease all;

		@include breakpoint(desktop-small) {
			transform: translateX(0px);
		}

		div {
			width: 40%;
			height: 3px;
			background-color: $color-green;
			transition: all 0.5s ease;
		}
	}

	&--active {
		span {
			transform: translateX(0%);

			div {
				transform: rotate(30deg);

				&:nth-child(1) {
					width: 20%;
				}

				&:nth-child(2) {
					width: 30%;
				}
			}
		}
	}

	&--hide {
		span {
			opacity: 0;
			transform: translateX(60px);
		}
	}
}

.menubar__info {
	display: none;

	@include breakpoint(desktop-small) {
		display: flex;
		justify-content: center;
		align-items: center;

		width: 60px;
		padding-top: 20px;

		color: $color-white-d-5;
		font-weight: 700;
		letter-spacing: 0.3rem;
		white-space: nowrap;
		writing-mode: vertical-lr;

		transition: 0.5s ease all;

		text-transform: uppercase;
	}

	@include breakpoint(desktop-normal) {
		width: 80px;
		padding-top: 30px;
	}

	@include breakpoint(desktop-large) {
		width: 100px;
		padding-top: 40px;
		font-size: 2rem;
	}

	&--active {
		opacity: 0;
		transform: translateY(-100%);
	}
}
