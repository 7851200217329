@import '../../scss/varables';
@import '../../scss/mixins';

.sudoku {
	position: relative;

	&-loading {
		position: absolute;
		top: -2rem;
		left: -2rem;
		right: -2rem;
		bottom: -2rem;
		z-index: 1;

		background-color: rgba($color-black-l-1, $alpha: 0.99);

		display: flex;
		justify-content: center;
		align-items: center;

		.simple-spinner {
			width: 100px;
			height: 100px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}

		.simple-spinner span {
			display: block;
			width: 100px;
			height: 100px;
			border: 10px solid transparent;
			border-radius: 50%;
			border-right-color: $color-green;
			border-bottom-color: $color-green-d-5;
			animation: spinner-anim 0.8s linear infinite;
		}

		@keyframes spinner-anim {
			from {
				transform: rotate(0);
			}
			to {
				transform: rotate(360deg);
			}
		}
	}

	//Correct
	&__section {
		padding-left: 0rem;
		padding-right: 0rem;
	}

	&__wrapper {
		max-width: 1400px !important;

		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		flex-direction: column;

		gap: 1rem;
	}

	//Correct
	&__board {
		display: grid;

		grid-template-rows: 36px repeat(7, 34px [row-start]) 36px;
		grid-template-columns: 36px repeat(7, 34px [col-start]) 36px;

		@include sudoku-breakpoint('500') {
			grid-template-rows: 40px repeat(7, 38px [row-start]) 40px;
			grid-template-columns: 40px repeat(7, 38px [col-start]) 40px;
		}

		@include sudoku-breakpoint('600') {
			grid-template-rows: 44px repeat(7, 42px [row-start]) 44px;
			grid-template-columns: 44px repeat(7, 42px [col-start]) 44px;
		}

		@include sudoku-breakpoint('1440') {
			grid-template-rows: 48px repeat(7, 46px [row-start]) 48px;
			grid-template-columns: 48px repeat(7, 46px [col-start]) 48px;
		}

		@include sudoku-breakpoint('1920') {
			grid-template-rows: 52px repeat(7, 50px [row-start]) 52px;
			grid-template-columns: 52px repeat(7, 50px [col-start]) 52px;
		}
	}

	//Correct
	&__header {
		width: 100%;
		max-width: 300px;
		display: flex;
		justify-content: space-between;
		font-size: 1.4rem;

		@include sudoku-breakpoint('500') {
			max-width: 340px;
		}

		@include sudoku-breakpoint('600') {
			max-width: 376px;
			font-size: 1.5rem;
		}

		@include sudoku-breakpoint('820') {
			flex-direction: row;
			max-width: 720px;

			border-bottom: 2px solid $color-white-d-6;
			padding-bottom: 0.5rem;
			margin-bottom: 0.1rem;
		}

		@include breakpoint(desktop-small) {
			max-width: 376px;
			border: none;
		}

		@include sudoku-breakpoint('1200') {
			flex-direction: row;
			max-width: 724px;
			font-size: 1.6rem;

			border-bottom: 2px solid $color-white-d-6;
			padding-bottom: 1rem;
			margin-bottom: 0.4rem;
		}

		@include sudoku-breakpoint('1440') {
			max-width: 764px;
		}

		@include sudoku-breakpoint('1920') {
			max-width: 800px;
			font-size: 1.7rem;
		}
	}

	//Visual Upgrade
	&__difficulty {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		&-levels {
			display: flex;
			gap: 0.5rem;

			@include sudoku-breakpoint('500') {
				gap: 1rem;
			}

			&-item {
				color: $color-white-d-5;
				font-weight: 400;
				font-style: italic;
				transition: 0.5s ease all;

				&--active {
					color: $color-green;
					font-weight: 700;
				}

				&:hover {
					cursor: pointer;
					color: $color-green;
				}
			}
		}
	}

	//Correct
	&__information {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	//Correct
	&__timer {
		display: flex;
		justify-content: center;
		align-items: center;

		svg {
			width: 1.4rem;
			height: 1.4rem;
			margin-left: 0.4rem;

			&:hover {
				color: $color-green;
			}

			@include sudoku-breakpoint('1440') {
				width: 1.6rem;
				height: 1.6rem;
			}

			@include sudoku-breakpoint('1920') {
				width: 1.7rem;
				height: 1.7rem;
			}
		}
	}

	//Correct
	&__body {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		gap: 1rem;

		@include sudoku-breakpoint('820') {
			flex-direction: row;
		}

		@include breakpoint(desktop-small) {
			flex-direction: column;
		}

		@include sudoku-breakpoint('1200') {
			flex-direction: row;
			gap: 4rem;
		}
	}

	//Correct
	&__controll {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		width: 100%;
		max-width: 300px;

		gap: 0.5rem;

		@include sudoku-breakpoint('500') {
			max-width: 340px;
		}

		@include sudoku-breakpoint('600') {
			max-width: 376px;
		}

		@include sudoku-breakpoint('820') {
			max-width: 334px;
			gap: 1rem;
		}

		@include sudoku-breakpoint('1024') {
			max-width: 376px;
			gap: 0.5rem;
		}

		@include sudoku-breakpoint('1200') {
			max-width: 310px;
			gap: 1rem;
		}

		@include sudoku-breakpoint('1440') {
			gap: 2rem;
		}
	}

	//Correct
	&__buttons {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 4rem;

		width: 100%;

		@include sudoku-breakpoint('820') {
			justify-content: space-evenly;
		}

		&-item {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 0.2rem;

			position: relative;

			border-bottom: none;
			border-radius: 50%;

			width: 70px;
			height: 70px;
			cursor: pointer;

			svg {
				width: 3rem;
				height: 3rem;
			}

			p {
				font-size: 1.4rem;
			}

			&:hover {
				color: $color-green;
			}

			&--notes {
				border-radius: 1rem;
				font-weight: 700;
				font-size: 1rem;
				position: absolute;
				background-color: $color-white-d-5;
				border: 2px solid $color-black-l-2;
				color: $color-black-l-2;
				top: 0px;
				right: 0px;
				width: 30px;
			}

			&--hint {
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: 700;

				font-size: 1.4rem;

				position: absolute;
				background-color: $color-white-d-5;
				border: 2px solid $color-black-l-2;
				color: $color-black-l-2;
				top: 0px;
				right: 10px;
				width: 25px;
				height: 25px;
			}

			&--active {
				background-color: $color-green;
			}

			&--off {
				color: $color-black-l-5;
			}
		}
	}

	//Correct
	&__numbers {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;

		position: relative;

		font-size: 3rem;
		font-weight: 700;

		width: 100%;

		@include sudoku-breakpoint('820') {
			justify-content: center;
			gap: 1rem;
		}

		@include sudoku-breakpoint('1024') {
			justify-content: space-between;
		}

		@include sudoku-breakpoint('1200') {
			justify-content: center;
		}

		&-item {
			cursor: pointer;
			padding: 0rem 0.3rem;

			display: flex;
			justify-content: center;
			align-items: center;

			&:hover,
			&--active {
				background-color: $color-green;
				color: $color-black-l-2;
			}

			@include sudoku-breakpoint('820') {
				width: 90px;
				height: 70px;
				background-color: #333;
				border-radius: 1rem;
			}

			@include sudoku-breakpoint('1024') {
				width: auto;
				height: auto;
				background-color: transparent;
			}

			@include sudoku-breakpoint('1200') {
				width: 90px;
				height: 70px;
				background-color: #333;
				border-radius: 1rem;
			}
		}
	}

	&__newgame {
		width: 100%;
		display: flex;
		justify-content: center;

		margin-top: 1rem;
		text-transform: uppercase;

		p {
			background-color: $color-green;
			color: $color-black-l-2;
			padding: 1rem 2rem;
			font-weight: 700;
			border-radius: 0.3rem;
			transition: 0.5s ease all;

			&:hover {
				background-color: $color-green-d-3;
				cursor: pointer;
			}
		}
	}

	&__wrapper-api {
		max-width: 1000px !important;
		//padding: 0 1rem;

		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		gap: 2rem;

		&-paragraph {
			width: 100%;
			padding: 1rem;
			background-color: $color-black-l-2;
		}

		&-ins {
			background-color: $color-black-l-2;
			border-radius: 0.2rem;
			padding: 2rem;
			margin: 0rem auto 0rem;
			width: 100%;
			max-width: 360px;

			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 1rem;

			text-align: left;
		}

		&-json {
			background-color: $color-black;
			border-radius: 0.2rem;
			padding: 2rem;
			margin: 0rem auto 0rem;

			width: 320px;

			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
		}
	}
}

.sudoku-overlay {
	position: absolute;
	top: -2rem;
	left: -2rem;
	right: -2rem;
	bottom: -2rem;
	z-index: 1;

	background-color: rgba($color-black-l-1, $alpha: 0.99);

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2rem;

	&__button {
		margin-top: 0.5rem;
		display: flex;
		gap: 2rem;

		&-cancel {
			font-family: inherit;
			font-size: inherit;

			cursor: pointer;
			padding: 1rem 2rem;

			color: $color-white-d-5;
			background-color: $color-black-l-3;
			border: none;
			border-radius: 0.2rem;
			font-weight: 700;

			text-decoration: none;
			transition: all 0.5s ease;

			&:hover,
			&:focus {
				outline: none;
				background-color: $color-black-l-2;
			}

			@include breakpoint(desktop-small) {
				padding: 1.3rem 2.4rem;
			}

			@include breakpoint(desktop-normal) {
				padding: 1.6rem 2.8rem;
			}
		}

		&-submit {
			font-family: inherit;
			font-size: inherit;

			padding: 1rem 2rem;

			color: $color-black-l-1;
			background-color: $color-green;
			border: none;
			border-radius: 0.2rem;
			font-weight: 700;

			text-decoration: none;
			transition: all 0.5s ease;

			&:hover,
			&:focus {
				outline: none;
				background-color: $color-green-d-5;
			}

			@include breakpoint(desktop-small) {
				padding: 1.3rem 2.4rem;
			}

			@include breakpoint(desktop-normal) {
				padding: 1.6rem 2.8rem;
			}
		}
	}
}

.sudoku-cell {
	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;

	font-size: 1.8rem;

	@include sudoku-breakpoint('600') {
		font-size: 2rem;
	}

	@include sudoku-breakpoint('1440') {
		font-size: 2.2rem;
	}

	@include sudoku-breakpoint('1920') {
		font-size: 2.4rem;
	}

	border: 1px solid $color-black-l-2;
	background-color: $color-black-l-3;
	outline: none;

	position: relative;

	&--active {
		background-color: $color-black-l-5;
	}

	&--same {
		background-color: $color-green-l-5;
		color: black;

		&-error {
			background-color: #ffc2ca;
			color: black;
		}
	}

	&--current {
		background-color: $color-green;
		color: black;

		&-error {
			background-color: #ff334e;
			color: black;
		}
	}

	&:hover {
		background-color: $color-green;
		color: $color-black;
	}

	&__notes {
		position: absolute;

		font-size: 0.8rem;

		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-self: center;

		p {
			width: 10px;
			height: 10px;

			display: flex;
			justify-content: center;
			align-items: center;

			@include sudoku-breakpoint('500') {
				width: 11px;
				height: 11px;
			}

			@include sudoku-breakpoint('1440') {
				width: 14px;
				height: 14px;
				font-size: 0.9rem;
			}

			@include sudoku-breakpoint('1920') {
				font-size: 1rem;
			}
		}
	}

	&:nth-child(1),
	&:nth-child(10),
	&:nth-child(19),
	&:nth-child(28),
	&:nth-child(37),
	&:nth-child(46),
	&:nth-child(55),
	&:nth-child(64),
	&:nth-child(73) {
		border-left: 4px solid black;
	}

	&:nth-child(9),
	&:nth-child(18),
	&:nth-child(27),
	&:nth-child(36),
	&:nth-child(45),
	&:nth-child(54),
	&:nth-child(63),
	&:nth-child(72),
	&:nth-child(81) {
		border-right: 4px solid black;
	}

	&:nth-child(1),
	&:nth-child(2),
	&:nth-child(3),
	&:nth-child(4),
	&:nth-child(5),
	&:nth-child(6),
	&:nth-child(7),
	&:nth-child(8),
	&:nth-child(9) {
		border-top: 4px solid black;
	}

	&:nth-child(73),
	&:nth-child(74),
	&:nth-child(75),
	&:nth-child(76),
	&:nth-child(77),
	&:nth-child(78),
	&:nth-child(79),
	&:nth-child(80),
	&:nth-child(81) {
		border-bottom: 4px solid black;
	}

	&:nth-child(3),
	&:nth-child(12),
	&:nth-child(21),
	&:nth-child(30),
	&:nth-child(39),
	&:nth-child(48),
	&:nth-child(57),
	&:nth-child(66),
	&:nth-child(75) {
		border-right: 2px solid black;
	}

	&:nth-child(4),
	&:nth-child(13),
	&:nth-child(22),
	&:nth-child(31),
	&:nth-child(40),
	&:nth-child(49),
	&:nth-child(58),
	&:nth-child(67),
	&:nth-child(76) {
		border-left: 2px solid black;
	}

	&:nth-child(6),
	&:nth-child(15),
	&:nth-child(24),
	&:nth-child(33),
	&:nth-child(42),
	&:nth-child(51),
	&:nth-child(60),
	&:nth-child(69),
	&:nth-child(78) {
		border-right: 2px solid black;
	}

	&:nth-child(7),
	&:nth-child(16),
	&:nth-child(25),
	&:nth-child(34),
	&:nth-child(43),
	&:nth-child(52),
	&:nth-child(61),
	&:nth-child(70),
	&:nth-child(79) {
		border-left: 2px solid black;
	}

	&:nth-child(19),
	&:nth-child(20),
	&:nth-child(21),
	&:nth-child(22),
	&:nth-child(23),
	&:nth-child(24),
	&:nth-child(25),
	&:nth-child(26),
	&:nth-child(27) {
		border-bottom: 2px solid black;
	}

	&:nth-child(28),
	&:nth-child(29),
	&:nth-child(30),
	&:nth-child(31),
	&:nth-child(32),
	&:nth-child(33),
	&:nth-child(34),
	&:nth-child(35),
	&:nth-child(36) {
		border-top: 2px solid black;
	}

	&:nth-child(46),
	&:nth-child(47),
	&:nth-child(48),
	&:nth-child(49),
	&:nth-child(50),
	&:nth-child(51),
	&:nth-child(52),
	&:nth-child(53),
	&:nth-child(54) {
		border-bottom: 2px solid black;
	}

	&:nth-child(55),
	&:nth-child(56),
	&:nth-child(57),
	&:nth-child(58),
	&:nth-child(59),
	&:nth-child(60),
	&:nth-child(61),
	&:nth-child(62),
	&:nth-child(63) {
		border-top: 2px solid black;
	}
}
