@import '../scss/mixins';
@import '../scss/varables';

.page {
	z-index: 998;

	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 2rem;

	@include breakpoint(desktop-small) {
		gap: 3rem;
	}

	&__loading {
		position: fixed;
		top: 0rem;
		left: 0rem;
		width: 100%;
		height: 100vh;
		z-index: 10;

		background-color: rgba($color-black-l-1, $alpha: 1);

		display: flex;
		justify-content: center;
		align-items: center;

		.simple-spinner {
			width: 100px;
			height: 100px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}

		.simple-spinner span {
			display: block;
			width: 100px;
			height: 100px;
			border: 10px solid transparent;
			border-radius: 50%;
			border-right-color: $color-green;
			border-bottom-color: $color-green;
			animation: spinner-anim 0.8s linear infinite;
		}

		@keyframes spinner-anim {
			from {
				transform: rotate(0);
			}
			to {
				transform: rotate(360deg);
			}
		}
	}
}
