$color-mono-d-5: #000000;
$color-mono-d-4: #0a0a0a;
$color-mono-d-3: #5c5c5c;
$color-mono-d-2: #8a8a8a;
$color-mono-d-1: #b8b8b8;
$color-mono: #e5e5e5;
$color-mono-l-1: #ebebeb;
$color-mono-l-2: #f0f0f0;
$color-mono-l-3: #f5f5f5;
$color-mono-l-4: #fafafa;
$color-mono-l-5: #ffffff;

$color-black: #000;
$color-black-l-1: #0a0a0a;
$color-black-l-2: #141414;
$color-black-l-3: #292929;
$color-black-l-4: #333333;
$color-black-l-5: #3d3d3d;

$color-white: #fff;
$color-white-d-1: #f5f5f5;
$color-white-d-2: #ebebeb;
$color-white-d-3: #e0e0e0;
$color-white-d-4: #d6d6d6;
$color-white-d-5: #cccccc;
$color-white-d-6: #999;

$color-green-d-5: #10602b;
$color-green-d-4: #147333;
$color-green-d-3: #17863c;
$color-green-d-2: #1a9945;
$color-green-d-1: #1dad4d;
$color-green: #20bf55;
$color-green-l-1: #25d861;
$color-green-l-2: #3cde72;
$color-green-l-3: #54e283;
$color-green-l-4: #6ce985;
$color-green-l-5: #85eaa7;

$color-blue-d-5: #0a111f;
$color-blue-d-4: #0c1425;
$color-blue-d-3: #0e172b;
$color-blue-d-2: #101b31;
$color-blue-d-1: #121e37;
$color-blue: #14213d;
$color-blue-l-1: #1e335e;
$color-blue-l-2: #29447e;
$color-blue-l-3: #33559f;
$color-blue-l-4: #3e67bf;
$color-blue-l-5: #5d80cb;

$color-yellow-d-5: #865301;
$color-yellow-d-4: #a16402;
$color-yellow-d-3: #bb7402;
$color-yellow-d-2: #d68502;
$color-yellow-d-1: #f19502;
$color-yellow: #fca311;
$color-yellow-l-1: #fdac29;
$color-yellow-l-2: #fdb541;
$color-yellow-l-3: #fdbe59;
$color-yellow-l-4: #fec871;
$color-yellow-l-5: #fed188;

$index-background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%232E2E2E' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%232E2E2E'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");

$main-background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='376' height='376' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23E5E5E5' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23E5E5E5'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
