@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Tomorrow:wght@400;700&display=swap');

@import './scss/varables';
@import './scss/mixins';

*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html {
	font-size: 62.5%; // 1rem = 10px
}

body {
	font-family: 'Tomorrow', sans-serif;
	font-weight: 400;
	font-size: 1.6rem;
	line-height: 1.6;

	color: $color-mono-l-5;

	background-color: $color-mono-d-5;
	background-attachment: fixed;
	background-image: $index-background;
	/* background by SVGBackgrounds.com */

	box-sizing: border-box;
	position: relative;
	z-index: 1;

	::selection {
		background: transparent;
	}
}
